/* eslint-disable @typescript-eslint/no-unused-vars */
import { AES, enc } from 'crypto-js';
import CONSTANTS from '../constants';
import { IAPIResponseStatus, IMockResponse, IOptionValue, IUserOptionValue, InactiveOptionValue } from '../interfaces';
import { IUser } from '../interfaces/user';
import { getAPIStatus, getRequest } from '../utils/api-request';
import { getFromInMemory } from '../utils/in-memory-storage';

const decrypt = (encryptedData: any, encryptionKey: any) => {
    try {
        const decryptedBytes = AES.decrypt(encryptedData, encryptionKey);
        const decryptedString = decryptedBytes.toString(enc.Utf8);
        if (!decryptedString) {
            throw new Error('Decryption Error: Decrypted string is empty');
        }
        const decryptedData = JSON.parse(decryptedString);
        return decryptedData;
    } catch (error) {
        console.log('Decryption Error:', error);
        return null;
    }
};
const updateLastSuccessfulApiTimestamp = (): void => {
    const currentTime = new Date().toISOString();
    localStorage.setItem('lastSuccessfulApiCall', currentTime);
};
const getUserRequest = async (
    url: string,
    goalHost = false,
): Promise<{ response: any; apiStatus: IAPIResponseStatus } | undefined> => {
    let res;
    try {
        res = await fetch(
            `${goalHost ? process.env.REACT_APP_GOAL_API_HOST : process.env.REACT_APP_API_HOST
            }/api/v1/${url}`,
            {
                headers: {
                    Authorization: ` Bearer ${getFromInMemory()}`,
                },
                credentials: 'include' as RequestCredentials,
            },
        );
        if (res.status === 401) {
            return { response: null, apiStatus: 'UNAUTHORIZED' };
        }
        if (res.status === 200 || res.status === 201)
            updateLastSuccessfulApiTimestamp();

        const response = await res.json();
        return { response, apiStatus: getAPIStatus(res.status) };
    } catch (err) {
        const response = <IMockResponse>{};
        response.message = CONSTANTS.ERROR_406;
        return {
            response,
            apiStatus: getAPIStatus(res ? res.status : 406), // default 406 failure
        };
    }
};

// eslint-disable-next-line max-lines-per-function
const getUserFromMail = async (
    emailId: string,
): Promise<{ apiStatus: IAPIResponseStatus; user: IUser } | undefined> => {
    try {
        const userRes = await getUserRequest(`users/by-email?email=${emailId}`);
        const encryptionKey = "3sNlQv7kp0JoGyW8zXtRi2c6mB";
        if (userRes?.apiStatus === 'SUCCESS') {
            const user: IUser = {
                id: 0,
                name: '',
                role: '',
                mail: emailId,
                isAdmin: false,
                isDcoAdmin: false,
                isClmAdmin: false,
                isTrapAdmin: false,
                jobTitle: '',
            };
            const decryptedData = await decrypt(userRes?.response.encrypteddata, encryptionKey);
            if (decryptedData.length > 0) {
                const [userObj] = decryptedData;
                // eslint-disable-next-line no-restricted-syntax
                for (const role of userObj.roles) {
                    if (role.name === CONSTANTS.AUTHORIZED_ROLE) {
                        user.isAdmin = true;
                    }
                    if (role.name === CONSTANTS.DCO_AUTHORIZED_ROLE) {
                        user.isDcoAdmin = true;
                    }
                    if (role.name === CONSTANTS.CLM_AUTHORIZED_ROLE) {
                        user.isClmAdmin = true;
                    }
                    if (role.name === CONSTANTS.TRAP_AUTHORIZED_ROLE){
                        user.isTrapAdmin = true;
                    }
                }
                user.id = userObj.id;
                user.name = userObj.employeeName;
                user.role = userObj?.roles[0]?.name;
                user.jobTitle = userObj?.jobTitle;
            }
            return { apiStatus: userRes.apiStatus, user };
        }
        if (userRes?.apiStatus === 'UNAUTHORIZED') {
            return {
                apiStatus: userRes.apiStatus,
                user: {
                    id: 0,
                    name: '',
                    role: '',
                    mail: emailId,
                    isAdmin: false,
                    isDcoAdmin: false,
                    isClmAdmin: false,
                    isTrapAdmin: false,
                    jobTitle: '',
                },
            };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const userSearch = async (
    query?: string,
    roleIds?: number[] | string[],
    userIds?: number[] | string[],
    includeInactive = false,
    groupId?: number,
): Promise<{ apiStatus: IAPIResponseStatus; users: IUserOptionValue[] } | undefined> => {
    try {
        let queryParam = `includeInactive=${includeInactive ? 1 : 0}`;
        if (query) {
            queryParam += `&query=${query}`;
        }
        if (roleIds && roleIds.length > 0) {
            queryParam += `&roleId=[${roleIds.toString()}]`;
        }
        if (groupId) {
            queryParam += `&groupId=[${groupId.toString()}]`;
        }
        if (userIds && userIds.length > 0) {
            queryParam += `&userIds=[${userIds.toString()}]`;
        }
        const userRes = await getRequest(`users/search?${queryParam}`);
        if (userRes?.apiStatus === 'SUCCESS') {
            const users: IUserOptionValue[] = [];
            if (userRes.response.length > 0) {
                // TODO : what to show if user having multiple roles (it may confuse in dropdown) from backend it should show roles present in filter
                let roleName = '';
                let roleid = 0;
                // eslint-disable-next-line no-restricted-syntax
                for (const user of userRes.response) {
                    if (user?.roles === undefined) {
                        roleName = '';
                        roleid = 0;
                    } else {
                        roleName = user?.roles[0]?.name;
                        roleid = user?.roles[0]?.id;
                    }

                    users.push({
                        jobTitle: user.jobTitle,
                        label: user.employeeName,
                        value: user.id,
                        role: roleName,
                        roleId: roleid,
                        activeUser: !!Number(user.activeUser),
                    });
                }
            }
            return { apiStatus: userRes.apiStatus, users };
        }
        return undefined;
    } catch (err) {
        // console.log(err)
        return undefined;
    }
};

const fetchHirerarchy = async (
    traveller: string
): Promise<{ apiStatus: IAPIResponseStatus; users: IUserOptionValue[] } | undefined> => {
    try {
        const queryParams = `traveller=${traveller}`;
        const hierarchy = await getRequest(`users/search-hierarchy?${queryParams}`);
        if (hierarchy?.apiStatus === 'SUCCESS') {
            const users: IUserOptionValue[] = [];
            if (hierarchy.response.length > 0) {
                const roleName = '';
                const roleid = 0;
                // eslint-disable-next-line no-restricted-syntax
                for (const user of hierarchy.response) {
                    users.push({
                        jobTitle: user.jobTitle,
                        label: user.employeeName,
                        value: user.id,
                        role: roleName,
                        roleId: roleid,
                        activeUser: !!Number(user.activeUser),
                    });
                }
            }
            return { apiStatus: hierarchy.apiStatus, users };
        }
        return undefined;
    } catch (error) {
        // console.log(error);
        return undefined;
    }
}

const fetchManagerReportees = async (
    email: string,
): Promise<{ apiStatus: IAPIResponseStatus; users: IOptionValue[] } | undefined> => {
    try {
        const queryParam = `email=${email}`;
        const res = await getRequest(`users/employees-by-managerId?${queryParam}`);
        if (res?.apiStatus === 'SUCCESS') {
            const users: IOptionValue[] = [];
            if (res.response.length > 0) {
                // eslint-disable-next-line no-restricted-syntax
                for (const user of res.response) {
                    users.push({
                        value: user.email,
                        label: user.employeeName,
                    })
                }
            }
            return {
                apiStatus: res.apiStatus,
                users
            }
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
}

const searchInactiveApprovers = async (
    documentId?: number,
    query?: string,
    // eslint-disable-next-line consistent-return
): Promise<{ apiStatus: IAPIResponseStatus; users: InactiveOptionValue[] } | undefined> => {
    try {
        let queryParam = `query=${query}`;
        if (documentId) {
            queryParam += `&documentId=${documentId}`;
        }
        const res = await getRequest(`stuck-documents/user-search?${queryParam}`);
        if (res?.apiStatus === 'SUCCESS') {
            const users: InactiveOptionValue[] = [];
            if (res.response.length > 0) {
                //       // eslint-disable-next-line no-console
                // console.log(res.response)
                // TODO : what to show if user having multiple roles (it may confuse in dropdown) from backend it should show roles present in filter
                // eslint-disable-next-line no-restricted-syntax
                for (const user of res.response) {
                    // eslint-disable-next-line no-console
                    users.push({
                        label: user.employeeName,
                        value: user.id,
                        activeUser: !!Number(user.activeUser),
                    });
                }
            }
            return { apiStatus: res.apiStatus, users };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

export { getUserFromMail, userSearch, searchInactiveApprovers, fetchHirerarchy, fetchManagerReportees };
