/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton';

import { Upload } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Oval } from 'react-loader-spinner';
import Toggle from '../../components/toggle/Toggle';
import './DocumentInfo.scss';
import {
    IButtonStatus,
    IDocStatus,
    IOptionValue,
    IOwnerChange,
    IPageType,
    IUserOptionValue,
} from '../../interfaces';
import CONSTANTS from '../../constants';
import { IDocumentActionTypes, IDocumentInfo } from '../../interfaces/document';
import {
    documentAction,
    exportPDF,
    downloadPDF,
    getDocumentRelationsInfo,
    updateDocumentVersionNumber,
    checkInDocument,
    getAllDocVersionIds,
    getDocumentsDetails,
    updateDCOfields,
} from '../../API/document';
import { FavoriteComponent, FutureChange, PartOfDCO } from '../../components/General';
import CopyText from '../../components/CopyText';
import ConfirmationModal, {
    IAcceptedType,
} from '../../components/confirmation-modal/ConfirmationModal';
import { IDocumentTypeInfo } from '../../interfaces/document-type';
import STATIC_CONTENT from '../../constants/StaticContent';
import { SkeletonText } from '../../components/Skeleton';
import Button from '../../components/button/Button';
import { formatDate, futureFormattedDate } from '../../utils/date';
import { EditSvg, OwnerChangeIcon, PlusIconSvg, WarnIcon } from '../../assets/images';
import OwnerSelectModal from '../../components/document-admin-settings/OwnerSelectModal';
import { ownerChangeAction } from '../../API/ownerChange';
import { useDocumentContext } from '../../contexts/document-provider';
// eslint-disable-next-line import/order
import qs from 'qs';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { IVersionOwner } from '../../interfaces/user';
import AddToChangeModal from './AddToChangeModal';
import CheckoutConfirmationModel from './CheckoutConfirmationModel';

const { Blob } = window;

interface HeadingProps {
    pageType: IPageType;
    docInfo: IDocumentInfo;
    docTypeInfo: IDocumentTypeInfo;
    activeDocVersion: IOptionValue;
    latestDocVersionId: number;
    documentId: number;
    onFavoriteClickHAndler: () => void;
    delegatorInfo: IOptionValue | null;
    handleOpenModal: (show: boolean) => void;
    activeVersionOwnerInfo: IVersionOwner;
    relationArr: any[];
    docVersionCheckedInFlag: boolean;
    setDocVersionCheckedInFlag: React.Dispatch<React.SetStateAction<boolean>>;
    latestrelationArr: any[];
}

const { DOCUMENT_INFO, CREATE_DOCUMENTS, DCO } = STATIC_CONTENT;

// eslint-disable-next-line max-lines-per-function
const Heading: React.FC<HeadingProps> = ({
    pageType,
    docInfo,
    docTypeInfo,
    activeDocVersion,
    latestDocVersionId,
    documentId,
    delegatorInfo,
    onFavoriteClickHAndler,
    handleOpenModal,
    activeVersionOwnerInfo,
    // relationArrLength,
    docVersionCheckedInFlag,
    setDocVersionCheckedInFlag,
    relationArr,
    latestrelationArr,
}: HeadingProps) => {
    const {
        allowViewInProcessDocFlag,
        activeDocVersionId,
        onDocumentCheckIn,
        checkInVersionOwner,
        setIsFieldsDataSaved,
    } = useDocumentContext();
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [showCheckoutConfirmationModal, setCheckoutConfirmationModal] = useState(false);
    const [confirmationModalData, setConfirmationModalData] = useState({
        type: 'DISCARD',
        onConfirm: () => {},
    });

    // const [latestrelationArr, setlatestrelationArr] = useState<any[]>([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // async function getDocumentRelations() {
    //     if (latestDocVersionId) {
    //         const res = await getDocumentRelationsInfo(latestDocVersionId);
    //         if (res?.apiStatus === 'SUCCESS') {
    //             setlatestrelationArr(res?.data);
    //         }
    //     }
    // }

    // useEffect(() => {
    //     getDocumentRelations();
    // }, [latestDocVersionId]);
    const { user } = useAuthDataContext();
    const navigate = useNavigate();
    const { search } = useLocation();
    const queryObj = qs.parse(search, { ignoreQueryPrefix: true });
    const onClick = async (type: IDocumentActionTypes) => {
        setButtonStatus('LOADING');
        const res = await documentAction(documentId, type, docTypeInfo);
        if (res?.apiStatus === 'SUCCESS') {
            if (type === 'REVERT') {
                const draftRelationArr = relationArr.filter((obj) => obj?.state === 'DRAFT');
                if (draftRelationArr.length > 0) {
                    const versionIdList = await getAllDocVersionIds([documentId]);
                    const docVersionIds = versionIdList ? versionIdList?.documentVersionIds : [];
                    const NewVersionData = await getDocumentsDetails(docVersionIds);
                    const DCOList = draftRelationArr.map((obj) => obj.activeVersionId);
                    const res1 = await updateDCOfields(DCOList, NewVersionData?.data, documentId);
                    if (res1?.apiStatus === 'SUCCESS')
                        toast.success(
                            'Reference has been updated to the old version in all the draft DCOs this document is part of',
                        );
                }
                toast.success(DOCUMENT_INFO.TOAST.SUCCESS_MSG.REVERTED);
                navigate({
                    pathname: `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                    search: `?doc_id=${documentId}`,
                });
            }
            if (type === 'DISCARD') {
                if (queryObj.dcoDocumentId) {
                    window.history.back();
                }
                else {
                    navigate(`/${CONSTANTS.RELATIVE_PATHS.dashboard.Url}`);
                }
                if (docTypeInfo.isDCO) toast.success(DOCUMENT_INFO.TOAST.SUCCESS_MSG.DELETED);
                else toast.success(DOCUMENT_INFO.TOAST.SUCCESS_MSG.DISCARDED);
            }
        } else {
            toast.error(DOCUMENT_INFO.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
        setButtonStatus('DEFAULT');
    };

    const handleCheckout = async () => {
        setButtonStatus('LOADING');
        const res = await onDocumentCheckIn(false, activeDocVersionId);
        if (res?.apiStatus === 'SUCCESS') {
            toast.success(DOCUMENT_INFO.TOAST.SUCCESS_MSG.CHECK_OUT);
            navigate(
                `${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`,
            )
            setDocVersionCheckedInFlag(false);
        } else {
            toast.error(DOCUMENT_INFO.TOAST.ERROR_MSG.CHECK_OUT);
        }
        setButtonStatus('DEFAULT');
    };
    const checkoutState = async () => {
        if (!docVersionCheckedInFlag && user.id !== checkInVersionOwner.id)
            setCheckoutConfirmationModal(true);
        else handleCheckout();
    };

    return (
        <>
            <div className="document-info__heading">
                <div>
                    <div className="title">
                        <div className="title-flex">
                            <h1>
                                {pageType === 'CREATE_DOCUMENT' ||
                                docTypeInfo.fullWidthTitleDesc ? (
                                    <SkeletonText text={docTypeInfo.title} width={200} />
                                ) : (
                                    <>
                                        <SkeletonText text={docTypeInfo.title} width={150} /> -
                                        <SkeletonText text={docInfo.title} width={80} />
                                    </>
                                )}
                            </h1>
                            {(pageType === 'DOCUMENT_DETAIL' || docInfo.docState === 'APPROVED') &&
                                !docTypeInfo.isFileType && (
                                    <FavoriteComponent
                                        docId={documentId}
                                        isActive={docInfo.isFavorite}
                                        onClickHandler={onFavoriteClickHAndler}
                                    />
                                )}
                        </div>
                        <>
                            <span
                                className={`status-pill ${
                                    docInfo?.activeDocumentState &&
                                    docInfo.activeDocumentState.toLocaleLowerCase()
                                }`}
                            >
                                {docInfo.activeDocumentState === 'NEW' ? (
                                    <Skeleton width={70} height={20} borderRadius={8} />
                                ) : docInfo.approvedOn &&
                                  docInfo.activeDocumentState ===
                                      CONSTANTS.DOCUMENT_STATUS.APPROVED ? (
                                    CONSTANTS.DOCUMENT_STATUS.APPROVED
                                ) : (
                                    CONSTANTS.DOCUMENT_STATUS[docInfo.activeDocumentState]
                                )}
                            </span>
                            {docTypeInfo.allowDCO && (
                                <>
                                    <div className="vertical-dash" />
                                    <div
                                        className={docInfo.docState === 'DRAFT' ? 'red-color' : ''}
                                    >
                                        {docInfo.docState === 'DRAFT' ? (
                                            <Link
                                            data-tip
                                            data-tooltip-id="tooltip"
                                            className="red-color"
                                            target="_blank"
                                            to={{
                                                pathname: `/document-details`,
                                                search: `?doc_id=${documentId}&latestVersion=true`,
                                            }}
                                            >
                                            <FutureChange docId={documentId} isActive={true} />
                                            </Link>
                                        ) : (
                                            <span data-tip data-tooltip-id="tooltip">
                                            <FutureChange docId={documentId} isActive={false} />
                                            </span>
                                        )}
                                        <ReactTooltip
                                            className="tooltip-docinfo"
                                            id="tooltip"
                                            place="right"
                                        >
                                            <div>
                                                {docInfo.docState === 'DRAFT'
                                                    ? 'There is an active revision in draft state'
                                                    : 'Latest revision is in published state'}
                                            </div>
                                        </ReactTooltip>
                                    </div>
                                    <div className="vertical-dash" />
                                    <div
                                        className={
                                            latestrelationArr.length > 0 &&
                                            latestrelationArr.some(
                                                (obj) => obj.state !== 'PUBLISHED',
                                            )
                                                ? 'red-color'
                                                : ''
                                        }
                                    >
                                        <div data-tip="" data-tooltip-id="tooltip1">
                                            {latestrelationArr.length > 0 &&
                                            latestrelationArr.some(
                                                (obj) => obj.state !== 'PUBLISHED',
                                            ) ? (
                                                <Link
                                                    className="red-color"
                                                    target="_blank"
                                                    to={{
                                                        pathname: `/document-details`,
                                                        search: `?doc_id=${
                                                            latestrelationArr.find(
                                                                (obj) => obj.state !== 'PUBLISHED',
                                                            ).id
                                                        }`,
                                                    }}
                                                >
                                                    <PartOfDCO isActive />
                                                </Link>
                                            ) : (
                                                <PartOfDCO isActive={false} />
                                            )}
                                        </div>
                                        <ReactTooltip
                                            className="tooltip-docinfo"
                                            id="tooltip1"
                                            place="right"
                                            clickable
                                        >
                                            {latestrelationArr.length > 0 &&
                                            latestrelationArr.some(
                                                (obj) => obj.state !== 'PUBLISHED',
                                            ) ? (
                                                <div>
                                                    <Link
                                                        className="link-css"
                                                        target="_blank"
                                                        to={{
                                                            pathname: `/document-details`,
                                                            search: `?doc_id=${
                                                                latestrelationArr.findIndex(
                                                                    (obj) =>
                                                                        obj.state ===
                                                                        'UNDER_REVIEW',
                                                                ) === -1
                                                                    ? latestrelationArr.find(
                                                                          (obj) =>
                                                                              obj.state !==
                                                                              'PUBLISHED',
                                                                      ).id
                                                                    : latestrelationArr.find(
                                                                          (obj) =>
                                                                              obj.state ===
                                                                              'UNDER_REVIEW',
                                                                      ).id
                                                            }`,
                                                        }}
                                                    >
                                                        {latestrelationArr.findIndex(
                                                            (obj) => obj.state === 'UNDER_REVIEW',
                                                        ) === -1
                                                            ? latestrelationArr.find(
                                                                  (obj) =>
                                                                      obj.state !== 'PUBLISHED',
                                                              ).documentNumber
                                                            : latestrelationArr.find(
                                                                  (obj) =>
                                                                      obj.state === 'UNDER_REVIEW',
                                                              ).documentNumber}
                                                    </Link>
                                                    <div className="title-margin">
                                                        {latestrelationArr.findIndex(
                                                            (obj) => obj.state === 'UNDER_REVIEW',
                                                        ) === -1
                                                            ? latestrelationArr.find(
                                                                  (obj) =>
                                                                      obj.state !== 'PUBLISHED',
                                                              ).title
                                                            : latestrelationArr.find(
                                                                  (obj) =>
                                                                      obj.state === 'UNDER_REVIEW',
                                                              ).title}
                                                    </div>
                                                    <div className="light-black">
                                                        {latestrelationArr.findIndex(
                                                            (obj) => obj.state === 'UNDER_REVIEW',
                                                        ) === -1
                                                            ? latestrelationArr.find(
                                                                  (obj) =>
                                                                      obj.state !== 'PUBLISHED',
                                                              ).state
                                                            : latestrelationArr.find(
                                                                  (obj) =>
                                                                      obj.state === 'UNDER_REVIEW',
                                                              ).state}
                                                    </div>
                                                </div>
                                            ) : (
                                                'This document is not part of any active DCO'
                                            )}
                                        </ReactTooltip>
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                    {pageType !== 'CREATE_DOCUMENT' && !docTypeInfo.fullWidthTitleDesc && (
                        <h4 className="description">
                            <SkeletonText text={docInfo.description} width={250} />
                        </h4>
                    )}
                </div>
                <div className="buttons">
                    {/* {(user.id === docInfo.author.id || user.id === activeVersionOwnerInfo?.id || user.isDcoAdmin) &&  (docTypeInfo.allowDCO || docTypeInfo.prefixCode?.includes("DCO")) &&
                        (queryObj.dcoDocumentId ? (
                            <></>
                        ) : (
                            <>
                                <span
                                    data-tooltip-id="ownerChange-action"
                                    style={{ cursor: 'pointer' }}
                                    data-tip
                                >
                                    <OwnerChangeIcon onClick={() => handleOpenModal(true)} />
                                </span>
                                <ReactTooltip
                                    place="top"
                                    variant="dark"
                                     
                                    id="ownerChange-action"
                                >
                                    {' '}
                                    <span>Change Ownership</span>
                                </ReactTooltip>
                            </>
                        ))} */}
                    {pageType === 'CREATE_DOCUMENT' && allowViewInProcessDocFlag && (
                        <CopyText
                            text={DOCUMENT_INFO.BUTTON.SHARE}
                            link={`${process.env.REACT_APP_HOST}${CONSTANTS.RELATIVE_PATHS.documentDetails.WPUrl}?doc_id=${documentId}`}
                        />
                    )}
                    {delegatorInfo?.value ? (
                        <p className="delegated-message">{`Delegated from ${delegatorInfo.label}`}</p>
                    ) : null}
                    {pageType === 'CREATE_DOCUMENT' &&
                        (docInfo.approvedVersionId && (activeDocVersion.value as number)) !==
                            docInfo.approvedVersionId &&
                        docInfo.docState !== 'REJECTED' &&
                        !docTypeInfo.allowDCO &&
                        !latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                        !docVersionCheckedInFlag && (
                            <Button
                                type="button"
                                disabled={
                                    buttonStatus === 'LOADING' ||
                                    docInfo.title === CONSTANTS.LOADING.TEXT
                                }
                                className=""
                                isLoading={
                                    buttonStatus === 'LOADING' &&
                                    confirmationModalData.type === 'REVERT'
                                }
                                onClick={() => {
                                    setConfirmationModal(true);
                                    setConfirmationModalData({
                                        onConfirm: () => onClick('REVERT'),
                                        type: 'REVERT',
                                    });
                                    setIsFieldsDataSaved(true);
                                }}
                                title="Revert to previous version"
                            >
                                {DOCUMENT_INFO.BUTTON.REVERT}
                            </Button>
                        )}
                    {pageType === 'CREATE_DOCUMENT' &&
                        !docInfo.approvedVersionId &&
                        !latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                        !docVersionCheckedInFlag &&
                        !(docTypeInfo.allowDCO || docTypeInfo.isDCO) && (
                            <Button
                                type="button"
                                disabled={
                                    buttonStatus === 'LOADING' ||
                                    docInfo.title === CONSTANTS.LOADING.TEXT
                                }
                                className=""
                                isLoading={
                                    buttonStatus === 'LOADING' &&
                                    confirmationModalData.type === 'REVERT'
                                }
                                onClick={() => {
                                    setConfirmationModal(true);
                                    setConfirmationModalData({
                                        onConfirm: () => onClick('DISCARD'),
                                        type: 'DISCARD',
                                    });
                                    setIsFieldsDataSaved(true);
                                }}
                                title={
                                    docTypeInfo.isDCO
                                        ? 'Delete the DCO permanently'
                                        : 'Delete the document permanently'
                                }
                            >
                                {DOCUMENT_INFO.BUTTON.DELETE}
                            </Button>
                        )}
                    {!(docTypeInfo.isDCO || docTypeInfo.allowDCO) &&
                        pageType === 'DOCUMENT_DETAIL' &&
                        (docInfo.docState === 'REJECTED' || docInfo.docState === 'DRAFT') &&
                        !latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                        !docVersionCheckedInFlag &&
                        Number(activeDocVersion.value) === latestDocVersionId &&
                        (docInfo.versionOwner.id === user.id || docTypeInfo.isDCO) &&
                        !docTypeInfo.isFileType && (
                            <button
                                type="button"
                                onClick={() =>
                                    navigate(
                                        `${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`,
                                    )
                                }
                            >
                                {DOCUMENT_INFO.BUTTON.EDIT}
                            </button>
                        )}
                    {/* {docInfo.docState === 'DRAFT' && docTypeInfo.allowDCO &&
                        (docVersionCheckedInFlag || docInfo.versionOwner.id !== user.id) && (latestrelationArr.length > 0 ? latestrelationArr.some((obj) => obj.state !== 'UNDER_REVIEW') : true) && (
                            <Button
                                type="button"
                                onClick={checkoutState}
                                disabled={
                                    buttonStatus === 'LOADING' ||
                                    docInfo.title === CONSTANTS.LOADING.TEXT
                                }
                                className=""
                                isLoading={buttonStatus === 'LOADING'}
                            >
                                {CREATE_DOCUMENTS.BUTTON.CHECK_OUT}
                            </Button>
                        )} */}
                </div>
                {showConfirmationModal && (
                    <ConfirmationModal
                        type={confirmationModalData.type as IAcceptedType}
                        isModalOpen={showConfirmationModal}
                        onModalClose={setConfirmationModal}
                        onConfirm={confirmationModalData.onConfirm}
                        isDCO={docTypeInfo.isDCO}
                    />
                )}
            </div>
        </>
    );
};

interface Prop {
    show: boolean;
    downloadable: boolean;
    handleDownload: () => void;
    handleShow: (check: boolean) => void;
    downloading: boolean;
}
// eslint-disable-next-line max-lines-per-function
const DownloadModal: React.FC<Prop> = ({
    show,
    downloadable,
    handleDownload,
    handleShow,
    downloading,
}) => {
    const customStyles = {
        content: {
            // backgroundColor: '#000',
            bottom: 'auto',
            left: '50%',
            marginRight: '-50%',
            right: 'auto',
            top: '20%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div>
            <Modal isOpen={show} style={customStyles} className="Owner-Change-Modal">
                <PlusIconSvg className="close-btn" onClick={() => handleShow(false)} />
                {downloadable ? (
                    <div style={{ display: 'flex' }}>
                        <label htmlFor="user-search">Your download is getting ready.</label>
                        <Oval color={CONSTANTS.BRAND_COLOR} height={20} width={20} />
                    </div>
                ) : (
                    <div style={{ width: '250px' }}>
                        <Button
                            disabled={false}
                            isLoading={downloading}
                            className=""
                            onClick={() => {
                                handleDownload();
                            }}
                            type={undefined}
                        >
                            Click Here to download
                        </Button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

interface Props {
    documentId: number;
    pageType: IPageType;
    docInfo: IDocumentInfo;
    docTypeInfo: IDocumentTypeInfo;
    docVersions: IOptionValue[];
    activeDocVersion: IOptionValue;
    latestDocVersionId: number;
    titleError: boolean;
    descError: boolean;
    effectiveFromError: boolean;
    effectivePeriodError: boolean;
    sunsetPeriodError: boolean;
    setDocInfo: React.Dispatch<React.SetStateAction<IDocumentInfo>>;
    setActiveDocVersion: React.Dispatch<React.SetStateAction<IOptionValue>>;
    onFavoriteClickHAndler: () => void;
    // eslint-disable-next-line react/require-default-props
    delegatorInfo?: IOptionValue;
    publishOnceApproved: boolean;
    isSunsetEnabled: boolean;
    activeVersionOwnerInfo: IVersionOwner;
    activeVersionApprovalDeadline: number;
    setActiveVersionApprovalDeadline: React.Dispatch<React.SetStateAction<number>>;
    // eslint-disable-next-line react/require-default-props
    checkInVersionOwner?: IVersionOwner;
    isEndDateAsked: boolean;
    relationArr: any[];
    setRelationArr: React.Dispatch<React.SetStateAction<any[]>>;
    latestrelationArr: any[];
    setlatestrelationArr: React.Dispatch<React.SetStateAction<any[]>>;
}

// eslint-disable-next-line max-lines-per-function
const DocumentInfo: React.FC<Props> = ({
    documentId,
    pageType,
    docInfo,
    docTypeInfo,
    docVersions,
    activeDocVersion,
    latestDocVersionId,
    titleError,
    descError,
    effectiveFromError,
    effectivePeriodError,
    sunsetPeriodError,
    setDocInfo,
    setActiveDocVersion,
    onFavoriteClickHAndler,
    delegatorInfo,
    publishOnceApproved,
    isSunsetEnabled,
    activeVersionOwnerInfo,
    activeVersionApprovalDeadline,
    setActiveVersionApprovalDeadline,
    checkInVersionOwner,
    isEndDateAsked,
    relationArr,
    setRelationArr,
    latestrelationArr,
    setlatestrelationArr,
}: Props) => {
    const [fileName, setFileName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [download, setDownload] = useState<boolean>(true);
    const [downloading, setDownloading] = useState<boolean>(false);
    const { user } = useAuthDataContext();
    const [openModal, setOpenModal] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const navigate = useNavigate();
    const { DOCUMENT_OWNER_CHANGE } = STATIC_CONTENT;
    const [selectedNewOwner, setSelectedNewOwner] = useState<IUserOptionValue | null>(null);
    const States: IDocStatus[] = ['APPROVED', 'PUBLISHED', 'UNDER_REVIEW'];
    const [openCustomVersionModal, setOpenCustomVersionModal] = useState(false);
    const {
        versionNumberInfo,
        docVersionCheckedInFlag,
        setDocVersionCheckedInFlag,
        saveFieldsData,
        docVersionsArr,
        activeDocVersionObj,
        setActiveDocVersionObj,
    } = useDocumentContext();
    const [majorV, setMajorV] = useState<number>(versionNumberInfo.majorVersionNumber);
    const [minorV, setMinorV] = useState<number>(versionNumberInfo.minorVersionNumber + 1);
    const [updateVersionLoading, setUpdateVersionLoading] = useState(false);
    // const [relationArr, setRelationArr] = useState<any[]>([]);
    // const [latestrelationArr, setlatestrelationArr] = useState<any[]>([]);
    const checkInMessage = docVersionCheckedInFlag ? 'checked-in' : 'checked-out';
    const [openAddToDCOModal, setopenAddToDCOModal] = useState(false);
    const [deadlineDays, setDeadlineDays] = useState<number>(activeVersionApprovalDeadline);
    const [docVersionList, setDocVersionList] = useState<IOptionValue[]>([]);
    async function getDocumentRelations() {
        if (documentId && activeDocVersion.value) {
            const res = await getDocumentRelationsInfo(Number(activeDocVersion.value));
            if (latestrelationArr.length === 0) {
                const res1 = await getDocumentRelationsInfo(Number(latestDocVersionId));
                if (res1?.apiStatus === 'SUCCESS') {
                    setlatestrelationArr(res1?.data);
                }
            }
            if (res?.apiStatus === 'SUCCESS') {
                setRelationArr(res?.data);
                if (latestDocVersionId === activeDocVersion.value) setlatestrelationArr(res?.data);
            }
        }
    }

    useEffect(() => {
        if (docVersionsArr) {
            const versionArr = docVersionsArr && docVersionsArr.length > 0 && docVersionsArr.map(doc => ({
                value: doc.id,
                label: `${doc.majorVersionNumber}.${doc.minorVersionNumber} - ${doc.state}`
            }));
            setDocVersionList(versionArr || []);
        }
    }, [docVersionsArr]);
    useEffect(() => {
        if (versionNumberInfo.majorVersionNumber) {
            setMajorV(versionNumberInfo.majorVersionNumber);
            setMinorV(versionNumberInfo.minorVersionNumber + 1);
        }
    }, [versionNumberInfo]);

    useEffect(() => {
        if (docTypeInfo && docTypeInfo.allowDCO) {
            getDocumentRelations();
        }
    }, [docTypeInfo, activeDocVersion, latestDocVersionId]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (!openAddToDCOModal) {
            getDocumentRelations();
        }
    }, [openAddToDCOModal]);

    const effectiveFromDate = (
        <div>
            <label htmlFor="effective_from">Effective From Date</label>

            <div className="react-datepicker">
                <ReactDatePicker
                    className={`${effectiveFromError ? 'error' : ''}`}
                    value={!docInfo?.effectiveFrom ? 'Today' : formatDate(docInfo.effectiveFrom)}
                    minDate={docTypeInfo.isContract ? new Date(0) : new Date()}
                    onChange={(date: Date | null) => {
                        if (date)
                            setDocInfo({ ...docInfo, effectiveFrom: date });
                    }}
                    disabled={
                        !(
                            pageType === 'CREATE_DOCUMENT' ||
                            (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                        ) ||
                        latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                        docVersionCheckedInFlag
                    }
                />
            </div>
        </div>
    );

    function handleDeadlineDaysChange(e: any) {
        const newDeadline = e.target.value;
        if (newDeadline > 0) {
            setActiveVersionApprovalDeadline(newDeadline);
        }
    }

    const approvalDeadline = (
        <div className="approval_deadline">
            <label htmlFor="effective_from">Current Approval Deadline</label>
            <input
                disabled
                type="number"
                value={activeVersionApprovalDeadline}
                onChange={handleDeadlineDaysChange}
            />
            <span>Days, </span>
            <div className="react-datepicker" data-tooltip-id = "datetooltip">
                <ReactDatePicker
                    wrapperClassName="datePickerCustomised"
                    className='datepickerclass'
                    value={
                        activeVersionApprovalDeadline
                            ? futureFormattedDate(activeVersionApprovalDeadline)
                            : futureFormattedDate(0)
                    }
                    minDate={new Date()}
                    onChange={() => {}}
                    disabled
                    // onChange={(date: Date) => {
                    //     const currDate = new Date();
                    //     const diffInMs = Math.abs(date.getTime()-currDate.getTime());
                    //     // const diffInMs = Math.abs(currDate - date);
                    //     const days = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
                    //     // console.log(`selected: ${date}, curr: ${currDate}, days diffs: ${days}`)
                    //     // console.log(`^^%%$$ days: ${newDeadline}`)
                    //     setActiveVersionApprovalDeadline(days);
                    // }}
                    // disabled={!(pageType === 'CREATE_DOCUMENT' || pageType === 'EDIT_DOCUMENT') || docInfo.documentLock || docVersionCheckedInFlag}
                />
            </div>
            <ReactTooltip id="datetooltip" place="right">
                    Edit approval deadline on additional approval stage
            </ReactTooltip>
        </div>
    );

    const everGreen = (
        <div>
            <label htmlFor="effective_from">Perpetual</label>
            <Toggle
                isChecked={docInfo.isEverGreen}
                onclick={() => setDocInfo({ ...docInfo, isEverGreen: !docInfo.isEverGreen })}
                isDisabled={
                    !(
                        pageType === 'CREATE_DOCUMENT' ||
                        (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                    ) ||
                    latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                    docVersionCheckedInFlag
                }
            />
        </div>
    );

    const autoRenew = (
        <div>
            <label htmlFor="effective_from">Auto Renew</label>
            <Toggle
                isChecked={docInfo.autoRenew}
                onclick={() => setDocInfo({ ...docInfo, autoRenew: !docInfo.autoRenew })}
                isDisabled={
                    !(
                        pageType === 'CREATE_DOCUMENT' ||
                        (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                    ) ||
                    latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                    docVersionCheckedInFlag
                }
            />
        </div>
    );

    const effectiveTillDate = (
        <div>
            <label htmlFor="effective_from">End Date</label>

            <div className="react-datepicker">
                <ReactDatePicker
                    className={`${effectiveFromError ? 'error' : ''}`}
                    value={!docInfo?.effectiveTill ? 'Today' : formatDate(docInfo.effectiveTill)}
                    minDate={docTypeInfo.isContract ? new Date(0) : new Date()}
                    onChange={(date: Date | null) => {
                        if (date)
                            setDocInfo({ ...docInfo, effectiveTill: date });
                    }}
                    disabled={
                        !(
                            pageType === 'CREATE_DOCUMENT' ||
                            (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                        ) ||
                        latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                        docVersionCheckedInFlag
                    }
                />
            </div>
        </div>
    );

    const effectiveFromOnceApproved = (
        <div>
            <label htmlFor="effective_from">Effective From Date</label>
            <div>
                <input
                    type="text"
                    placeholder="Once Approved"
                    className="effectivePeriodError"
                    disabled
                />
            </div>
        </div>
    );
    const effectivePeriod = (
        <div>
            <label htmlFor="sunset-period">
                Effective Period (in weeks) <span className="mandatory-field" />
            </label>
            <div>
                <input
                    type="number"
                    className={`${effectivePeriodError ? 'error' : ''} sunset-period`}
                    value={docInfo.effectivePeriod}
                    disabled={
                        !(pageType === 'CREATE_DOCUMENT') ||
                        latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                        docVersionCheckedInFlag
                    }
                    onWheel={(event) => event.currentTarget.blur()}
                    onKeyDown={(e) =>
                        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) =>
                        setDocInfo({
                            ...docInfo,
                            effectivePeriod: e.target.valueAsNumber,
                        })
                    }
                />
            </div>
        </div>
    );
    const sunsetPeriod = (
        <div>
            <label htmlFor="sunset-period">
                {docTypeInfo.isContract ? 'Notice Period' : 'Sunset Period'} (in weeks) <span className="mandatory-field" />
            </label>
            <div>
                <input
                    type="number"
                    className={`${sunsetPeriodError ? 'error' : ''} sunset-period`}
                    value={docInfo.sunsetPeriod}
                    disabled={
                        !(pageType === 'CREATE_DOCUMENT') ||
                        latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                        docVersionCheckedInFlag
                    }
                    onWheel={(event) => event.currentTarget.blur()}
                    onKeyDown={(e) =>
                        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) =>
                        setDocInfo({
                            ...docInfo,
                            sunsetPeriod: e.target.valueAsNumber,
                        })
                    }
                />
            </div>
        </div>
    );

    const noticePeriod = (
        <div>
            <label htmlFor="sunset-period">
                {docTypeInfo.isContract ? 'Notice Period' : 'Sunset Period'} (in days) <span className="mandatory-field" />
            </label>
            <div>
                <input
                    type="number"
                    className={`${sunsetPeriodError ? 'error' : ''} sunset-period`}
                    value={docInfo.sunsetPeriod}
                    disabled={
                        !(
                            pageType === 'CREATE_DOCUMENT' ||
                            (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                        ) ||
                        latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                        docVersionCheckedInFlag
                    }
                    onWheel={(event) => event.currentTarget.blur()}
                    onKeyDown={(e) =>
                        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) =>
                        setDocInfo({
                            ...docInfo,
                            sunsetPeriod: e.target.valueAsNumber,
                        })
                    }
                />
            </div>
        </div>
    );

    // eslint-disable-next-line max-lines-per-function
    const versionFieldsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentMinorVersion = versionNumberInfo.minorVersionNumber;
        const currentMajorVersion = versionNumberInfo.majorVersionNumber;
        if (docVersions.length > 1) {
            const lastApprovedVersion = docVersions[docVersions.length - 2];
            const versionNumber = lastApprovedVersion.label.split('.');
            const lastMinorVersion = parseInt(versionNumber[1], 10);
            const lastMajorVersion = parseInt(versionNumber[0], 10);
            const newVersion = Number(e.target.value);
            if (e.target.id === 'minor') {
                if (
                    (newVersion >= 0 && newVersion >= lastMinorVersion) ||
                    (newVersion >= 0 && newVersion <= lastMinorVersion && majorV > lastMajorVersion)
                ) {
                    setMinorV(newVersion);
                }
            } else if (e.target.id === 'major') {
                if (newVersion >= 0 && newVersion >= lastMajorVersion) {
                    if (newVersion === lastMajorVersion && minorV <= lastMinorVersion) {
                        setMinorV(lastMinorVersion + 1);
                    }
                    setMajorV(newVersion);
                }
            }
        } else {
            const newVersion = Number(e.target.value);
            if (e.target.id === 'minor') {
                if (newVersion >= 0) {
                    setMinorV(newVersion);
                }
            } else if (e.target.id === 'major') {
                if (newVersion >= 0) {
                    setMajorV(newVersion);
                }
            }
        }
    };

    const handleAction = async () => {
        setIsSubmitLoading(true);
        await saveFieldsData(false);
        const documents: IOwnerChange[] = [
            {
                documentId,
                oldOwnerId: docInfo.author.id,
                state: '',
                docNumber: '',
            },
        ];
        if (!selectedNewOwner?.value) {
            toast.error(DOCUMENT_OWNER_CHANGE.OWNER.WARNING);
        } else {
            const res = await ownerChangeAction(documents, selectedNewOwner?.value as number);
            if (res?.apiStatus === 'FAILURE') {
                toast.error(DOCUMENT_OWNER_CHANGE.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
            } else if (res?.errors.length) {
                toast.error(DOCUMENT_OWNER_CHANGE.TOAST.WARNING_MSG.DUPLICATE);
            } else if (res?.apiStatus === 'SUCCESS') {
                toast.success(DOCUMENT_OWNER_CHANGE.TOAST.SUCCESS_MSG.AUTHOR_CHANGED);
                onFavoriteClickHAndler();
            }
        }
        setIsSubmitLoading(false);
        setOpenModal(false);
    };

    const handleExport = async () => {
        setLoading(true);
        const pdf: string = await exportPDF(documentId);
        setFileName(pdf);
        setDownload(false);
    };

    const handleDownloadPDF = async () => {
        setDownloading(true);
        const res = await downloadPDF(fileName);
        setDownloading(false);
        setLoading(false);
        setDownload(true);
    };

    const handleVersionUpdate = async () => {
        setUpdateVersionLoading(true);
        await saveFieldsData(false);
        // requires doc version id
        const currDocVersionId = Number(activeDocVersion.value);
        const res = await updateDocumentVersionNumber(documentId, currDocVersionId, majorV, minorV);
        if (res?.apiStatus === 'SUCCESS') {
            const updatedVersionObject: IOptionValue = {
                value: res.data?.id,
                label: `${res.data?.majorVersionNumber}.${res.data?.minorVersionNumber}`,
            };

            setActiveDocVersion(updatedVersionObject);
            setOpenCustomVersionModal(false);
            toast.success('Updated version number successfully');
        } else {
            toast.error('Could not update version number');
        }
        setUpdateVersionLoading(false);
    };

    return (
        <div className="document-info">
            <Heading
                pageType={pageType}
                activeDocVersion={activeDocVersion}
                latestDocVersionId={latestDocVersionId}
                docInfo={docInfo}
                documentId={documentId}
                onFavoriteClickHAndler={onFavoriteClickHAndler}
                docTypeInfo={docTypeInfo}
                delegatorInfo={delegatorInfo || null}
                handleOpenModal={(show: boolean) => {
                    setOpenModal(show);
                }}
                activeVersionOwnerInfo={activeVersionOwnerInfo}
                docVersionCheckedInFlag={docVersionCheckedInFlag}
                setDocVersionCheckedInFlag={setDocVersionCheckedInFlag}
                relationArr={relationArr}
                latestrelationArr={latestrelationArr}
            />
            {(pageType === 'CREATE_DOCUMENT' ||
                pageType === 'EDIT_DOCUMENT' ||
                docTypeInfo.fullWidthTitleDesc) && (
                <div
                    className={`section one ${
                        docTypeInfo.fullWidthTitleDesc ? 'full-width-section' : ''
                    }`}
                >
                    <div>
                        <label htmlFor="document-title">
                            {DOCUMENT_INFO.LABEL.TITLE} <span className="mandatory-field" />
                        </label>
                        {docInfo.title === CONSTANTS.LOADING.TEXT ? (
                            <Skeleton width="60%" containerClassName="skeleton-loading" />
                        ) : (
                            <input
                                type="text"
                                className={`${
                                    titleError
                                        ? docTypeInfo.fullWidthTitleDesc
                                            ? 'error document-title title-full-width'
                                            : 'error document-title'
                                        : docTypeInfo.fullWidthTitleDesc
                                        ? 'document-title title-full-width'
                                        : 'document-title'
                                }`}
                                name="document-title"
                                autoComplete="off"
                                autoCapitalize="on"
                                placeholder="Enter Title"
                                value={
                                    docInfo.title === CONSTANTS.LOADING.TEXT ? '' : docInfo.title
                                }
                                disabled={
                                    !(
                                        pageType === 'CREATE_DOCUMENT' ||
                                        (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                                    ) ||
                                    latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                                    docVersionCheckedInFlag
                                }
                                onChange={(e) => setDocInfo({ ...docInfo, title: e.target.value })}
                            />
                        )}
                    </div>
                    <div>
                        <label htmlFor="document-description">
                            {DOCUMENT_INFO.LABEL.DESCRIPTION} <span className="mandatory-field" />
                        </label>
                        {docInfo.description === CONSTANTS.LOADING.TEXT ? (
                            <Skeleton width="60%" containerClassName="skeleton-loading" />
                        ) : docTypeInfo.fullWidthTitleDesc ? (
                            <textarea
                                name="document-description"
                                id="document-description"
                                rows={4}
                                placeholder="Enter Description"
                                className={`${
                                    descError
                                        ? 'error document-description'
                                        : 'document-description'
                                }`}
                                disabled={
                                    !(pageType === 'CREATE_DOCUMENT') ||
                                    latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                                    docVersionCheckedInFlag
                                }
                                value={
                                    docInfo.description === CONSTANTS.LOADING.TEXT
                                        ? ''
                                        : docInfo.description
                                }
                                onChange={(e) =>
                                    setDocInfo({ ...docInfo, description: e.target.value })
                                }
                            />
                        ) : (
                            <input
                                type="text"
                                className={`${descError ? 'error' : ''}`}
                                name="document-description"
                                autoComplete="off"
                                autoCapitalize="on"
                                placeholder="Enter Description"
                                value={
                                    docInfo.description === CONSTANTS.LOADING.TEXT
                                        ? ''
                                        : docInfo.description
                                }
                                disabled={
                                    !(
                                        pageType === 'CREATE_DOCUMENT' ||
                                        (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                                    ) ||
                                    latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                                    docVersionCheckedInFlag
                                }
                                onChange={(e) =>
                                    setDocInfo({ ...docInfo, description: e.target.value })
                                }
                            />
                        )}
                    </div>
                </div>
            )}
            <div className="detail-section">
                <div>
                    {docTypeInfo.isDCO ? (
                        <label>{DCO.TITLE}</label>
                    ) : (
                        <label>{DOCUMENT_INFO.LABEL.DOCUMENT_NUMBER}</label>
                    )}
                    <CopyText text={docInfo.docNumber} />
                </div>
                <div>
                    <label>
                        {' '}
                        {docTypeInfo.allowDCO
                            ? DOCUMENT_INFO.LABEL.OWNER
                            : docTypeInfo.isDCO
                            ? DCO.CREATOR
                            : DOCUMENT_INFO.LABEL.AUTHOR}{' '}
                    </label>
                    <CopyText text={docInfo.author?.name} />
                    {docInfo.docState === 'DRAFT' &&
                        docInfo.versionOwner.id === user.id &&
                        docTypeInfo.allowDCO &&
                        !latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                        !docVersionCheckedInFlag &&
                        window.location.pathname.includes('/create-document') && (
                            <div style={{ order: 3 }}>
                                <EditSvg
                                    style={{
                                        cursor: 'pointer',
                                        float: 'right',
                                        position: 'relative',
                                    }}
                                    className="edit-icon"
                                    onClick={() => setOpenModal(true)}
                                />
                            </div>
                        )}
                </div>
                {docTypeInfo.isDCO && (
                    <div>
                        <label>{DCO.SUBMITTER}</label>
                        <CopyText text={docInfo.submitter?.name || 'N/A'} />
                    </div>
                )}
                {docTypeInfo.allowDCO && (
                    <div>
                        <label>{DOCUMENT_INFO.LABEL.ITEM_CREATOR}</label>
                        <CopyText text={docInfo.itemCreator?.name} />
                    </div>
                )}
                <div
                    style={{ display: docTypeInfo.blockRevise || docTypeInfo.isDCO ? 'none' : '' }}
                >
                    <label>{DOCUMENT_INFO.LABEL.VERSION}</label>
                    <Select
                        name="version"
                        id="version"
                        isMulti={false}
                        className="react-select"
                        classNamePrefix="select"
                        options={pageType ==="TASK_DETAILS" ? docVersions : docVersionList}
                        value={pageType ==="TASK_DETAILS" ? {value: activeDocVersion.value, label: `${activeDocVersion.label} - ${docInfo.docState}`} : activeDocVersionObj}
                        isDisabled={
                            pageType === 'CREATE_DOCUMENT' ||
                            pageType === 'TASK_DETAILS' ||
                            pageType === 'DOCUMENT_PREVIEW' ||
                            pageType === 'EDIT_DOCUMENT'
                        }
                        onChange={(option) => {
                            if (option && option.value) {
                                setActiveDocVersion(option);
                                setActiveDocVersionObj(option);
                                // toast.success(`Loaded version ${option.label}`);
                            }
                        }}
                        formatOptionLabel={(option) =>
                            pageType === 'DOCUMENT_DETAIL' ? (
                                <>
                                    <span
                                        className={
                                            option.value === docInfo.approvedVersionId
                                                ? 'dot'
                                                : 'space'
                                        }
                                    />
                                    <span
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{ __html: option.label }}
                                    />
                                </>
                            ) : (
                                <span
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{ __html: option.label }}
                                />
                            )
                        }
                        // components={{
                        //     Option: (optionProps) => (
                        //       <CustomOption
                        //         {...optionProps}
                        //         label={optionProps.label}
                        //         isPublished={optionProps.value === docInfo.approvedVersionId}
                        //       />
                        //     )
                        //   }}
                    />
                    {docInfo.docState === 'DRAFT' &&
                        docInfo.versionOwner.id === user.id &&
                        docTypeInfo.allowCustomVersioning &&
                        !latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                        !docVersionCheckedInFlag &&
                        window.location.pathname.includes('/create-document') && (
                            <div style={{ order: 3 }}>
                                <EditSvg
                                    style={{
                                        cursor: 'pointer',
                                        float: 'right',
                                        position: 'relative',
                                    }}
                                    className="edit-icon"
                                    onClick={() => setOpenCustomVersionModal(true)}
                                />
                            </div>
                        )}
                    {openCustomVersionModal && (
                        <div className="action-modal">
                            <Modal
                                isOpen={openCustomVersionModal}
                                onRequestClose={() => setOpenCustomVersionModal(false)}
                                contentLabel="Example Modal"
                                className="action__modal"
                            >
                                <PlusIconSvg
                                    className="close-btn"
                                    onClick={() => setOpenCustomVersionModal(false)}
                                />
                                <div className="revise-modal">
                                    <h4>Set the new version</h4>
                                    <div className="custom-version-inputs">
                                        <input
                                            type="number"
                                            id="major"
                                            value={majorV}
                                            onChange={(e) => versionFieldsHandler(e)}
                                        />
                                        <div>
                                            <p>.</p>
                                        </div>
                                        <input
                                            type="number"
                                            id="minor"
                                            value={minorV}
                                            onChange={(e) => versionFieldsHandler(e)}
                                        />
                                    </div>
                                    <Button
                                        type="button"
                                        className=""
                                        disabled={updateVersionLoading}
                                        onClick={() => handleVersionUpdate()}
                                        isLoading={updateVersionLoading}
                                    >
                                        Update Version
                                    </Button>
                                </div>
                            </Modal>
                        </div>
                    )}
                </div>
                {!docTypeInfo.isFileType ? (
                    <div>
                        <label htmlFor="revise-duration">{DOCUMENT_INFO.LABEL.CONFIDENTIAL}</label>
                        <Toggle
                            isChecked={docTypeInfo.isConfidential || docInfo.confidential}
                            onclick={() =>
                                setDocInfo({ ...docInfo, confidential: !docInfo.confidential })
                            }
                            isDisabled={
                                docTypeInfo.isConfidential ||
                                !(
                                    pageType === 'CREATE_DOCUMENT' ||
                                    (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                                ) ||
                                latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                                docVersionCheckedInFlag
                            }
                        />
                    </div>
                ) : null}
                {docTypeInfo.allowDCO && (
                    <div>
                        <Button
                            type="button"
                            onClick={() => {
                                setopenAddToDCOModal(true);
                            }}
                            disabled={
                                openAddToDCOModal ||
                                docInfo.title === CONSTANTS.LOADING.TEXT ||
                                (docInfo.docState === 'DRAFT' && latestrelationArr.length > 0) ||
                                latestrelationArr.length > 1
                            }
                            className="add-to-change"
                            isLoading={false}
                        >
                            {CREATE_DOCUMENTS.BUTTON.ADD_TO_CHANGE}
                        </Button>
                        <AddToChangeModal
                            show={openAddToDCOModal}
                            handleClose={() => setopenAddToDCOModal(false)}
                            childDocVersionId={latestDocVersionId}
                            latestrelationArr={latestrelationArr}
                            docVersionCheckedInFlag={docVersionCheckedInFlag}
                        />
                    </div>
                )}
                <OwnerSelectModal
                    show={openModal}
                    handleShow={(show: boolean) => setOpenModal(show)}
                    IssubmitLoading={isSubmitLoading}
                    updateChangedOwner={(selectedUser: IUserOptionValue) =>
                        setSelectedNewOwner(selectedUser)
                    }
                    handleOwnerChange={() => {
                        handleAction();
                    }}
                />
                {docTypeInfo.allowExport &&
                    (docInfo.docState === 'PUBLISHED' ||
                        docInfo.docState === 'APPROVED' ||
                        docInfo.docState === 'REPUBLISHED' ||
                        docInfo.docState === 'UNDER_REVIEW') && (
                        <div className="export-doc">
                            <>
                                <Upload
                                    onClick={() => {
                                        handleExport();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                                <label>Export</label>
                            </>
                        </div>
                    )}
                <DownloadModal
                    show={loading}
                    downloadable={download}
                    handleDownload={() => {
                        handleDownloadPDF();
                    }}
                    handleShow={(load: boolean) => {
                        setLoading(load);
                    }}
                    downloading={downloading}
                />
            </div>
            {/* Effectivity */}
            <div className="effectivity">
                {!(docTypeInfo.isDCO || docTypeInfo.allowDCO) && (
                    <>
                        {isSunsetEnabled &&
                            publishOnceApproved &&
                            docInfo.title !== CONSTANTS.LOADING.TEXT && (
                                <>
                                    {effectiveFromOnceApproved} {effectivePeriod} {sunsetPeriod}
                                </>
                            )}

                        {isSunsetEnabled &&
                            !publishOnceApproved &&
                            isEndDateAsked &&
                            docInfo.title !== CONSTANTS.LOADING.TEXT && (
                                <>
                                    {effectiveFromDate}
                                    {everGreen}
                                    {docInfo.isEverGreen ? null : effectiveTillDate}
                                    {docInfo.isEverGreen ? null : autoRenew}
                                    {noticePeriod}
                                </>
                            )}
                        {isSunsetEnabled &&
                            !publishOnceApproved &&
                            !isEndDateAsked &&
                            docInfo.title !== CONSTANTS.LOADING.TEXT && (
                                <>
                                    {effectiveFromDate}
                                    {effectivePeriod}
                                    {sunsetPeriod}
                                </>
                            )}
                        {!isSunsetEnabled &&
                            !publishOnceApproved &&
                            docInfo.title !== CONSTANTS.LOADING.TEXT &&
                            effectiveFromDate}
                    </>
                )}
                {docTypeInfo.enableApprovalDeadline && approvalDeadline}
            </div>

            {relationArr.length && relationArr.some((obj) => obj.state === 'PUBLISHED') ? (
                <div className="relation-info">
                    <div className="warning">
                        <WarnIcon className="warn-icon" />
                    </div>
                    <div>{STATIC_CONTENT.DCO.PUBLISHED_DOCUMENT}</div>
                    {relationArr.map(
                        (curr) =>
                            curr.state === 'PUBLISHED' && (
                                <div>
                                    <Link
                                        className="link-style"
                                        target="_blank"
                                        style={{ textDecoration: 'none' }}
                                        to={{
                                            pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                                            search: `?doc_id=${curr.id}`,
                                        }}
                                    >
                                        <SkeletonText text={curr.documentNumber} width={200} />
                                    </Link>
                                    {/* <span>{curr?.owner?.employeeName}</span> */}
                                </div>
                            ),
                    )}
                </div>
            ) : null}

            {latestrelationArr.length &&
            docInfo.docState === 'PUBLISHED' &&
            latestrelationArr.some((obj) => obj.state !== 'PUBLISHED') ? (
                <div className="relation-info">
                    <div className="warning">
                        <WarnIcon className="warn-icon" />
                    </div>
                    <div>{STATIC_CONTENT.DCO.PUBLISHED_DOCUMENT_ADDED_TO_DCO}</div>
                    {latestrelationArr.map(
                        (curr) =>
                            curr.state !== 'PUBLISHED' && (
                                <div>
                                    <Link
                                        className="link-style"
                                        target="_blank"
                                        style={{ textDecoration: 'none' }}
                                        to={{
                                            pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                                            search: `?doc_id=${curr.id}`,
                                        }}
                                    >
                                        <SkeletonText text={curr.documentNumber} width={200} />
                                    </Link>
                                </div>
                            ),
                    )}
                </div>
            ) : null}

            {latestrelationArr.length &&
            docInfo.docState !== 'PUBLISHED' &&
            Number(activeDocVersion.value) === latestDocVersionId ? (
                <div className="relation-info">
                    <div className="warning">
                        <WarnIcon className="warn-icon" />
                    </div>
                    <div>{STATIC_CONTENT.DCO.ADDED_AND_LOCKED}</div>
                    {latestrelationArr.map(
                        (curr) =>
                            curr.state !== 'PUBLISHED' && (
                                <div>
                                    <Link
                                        className="link-style"
                                        target="_blank"
                                        style={{ textDecoration: 'none' }}
                                        to={{
                                            pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                                            search: `?doc_id=${curr.id}`,
                                        }}
                                    >
                                        <SkeletonText text={curr.documentNumber} width={200} />
                                    </Link>
                                    {/* <span>{curr?.owner?.employeeName}</span> */}
                                </div>
                            ),
                    )}
                </div>
            ) : null}

            {docTypeInfo.allowDCO && !docInfo.documentLock && !relationArr.length ? (
                <div className="relation-info">
                    <div className="warning">
                        <WarnIcon className="warn-icon" />
                    </div>
                    <div>{STATIC_CONTENT.DCO.ALLOWED_DCO_CHILD_NOTE}</div>
                </div>
            ) : null}

            {docTypeInfo.allowReviseByAnyone &&
                docTypeInfo.allowDCO &&
                docInfo.docState === 'DRAFT' &&
                (pageType === 'DOCUMENT_DETAIL' ||
                    (pageType === 'CREATE_DOCUMENT' && checkInMessage === 'checked-in')) && (
                    <div className="relation-info">
                        <div className="warning">
                            <WarnIcon className="warn-icon" />
                        </div>
                        This document has been {checkInMessage} by:{' '}
                        {checkInVersionOwner?.employeeName}{' '}
                        <a
                            href={`mailto:${checkInVersionOwner?.email}`}
                            style={{ color: '#2079FF', textDecoration: 'none' }}
                        >
                            {checkInVersionOwner?.email}
                        </a>
                        {latestrelationArr.length > 0 &&
                            docInfo.activeDocumentState !== 'DRAFT' && (
                                <span> and is part of </span>
                            )}
                        {docInfo.activeDocumentState !== 'DRAFT' &&
                            latestrelationArr.map((curr) => (
                                <div>
                                    <Link
                                        className="link-style"
                                        target="_blank"
                                        style={{ textDecoration: 'none' }}
                                        to={{
                                            pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                                            search: `?doc_id=${curr.id}`,
                                        }}
                                    >
                                        <SkeletonText text={curr.documentNumber} width={200} />
                                    </Link>
                                    {/* <span>{curr?.owner?.employeeName}</span> */}
                                </div>
                            ))}
                    </div>
                )}
        </div>
    );
};
export default DocumentInfo;
